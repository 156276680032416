import { Box, Button, Tab, TabList, Tabs, Typography } from "@mui/joy";
import React, { useState } from "react";
import { batchType } from "../../../Constants/batch-tabs";
import BatchDetails from "./batch-components/batch-details";
import VerifyStudents from "./batch-components/verify-student";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, setEmptyFormFilled, setFormFilled } from "../../../Store/slices/batchSlice";
import { addBatchThunk } from "../../../Store/actions/batchAction";
import ScheduleCourse from "../batch-info/batch-info-components/schedule-course";
import BatchEvents from "../batch-info/batch-info-components/batch-event";
import WithAuth from "../../utils/WithAuth";
import { useNavigate } from "react-router-dom";
import { setTopicSessions } from "../../../Store/slices/topicSessionSlice";
import TeacherDetails from "./batch-components/teacher-details/teacher-details";

const AddNewBatch = () => {
	const { activeTab, isFormFilled, selectedCourse, batchDetails } = useSelector((state) => state.batch);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleTabChange = (event, newValue) => {
		if (isFormFilled[newValue] && activeTab !== newValue) dispatch(setActiveTab(newValue));
	};

	const handleContinue = () => {
		if (activeTab === batchType.batchDetails) {
			dispatch(setActiveTab(batchType.verifyStudents));
		} else if (activeTab === batchType.verifyStudents) {
			dispatch(setActiveTab(batchType.teacherDetails));
		} else if (activeTab === batchType.teacherDetails) {
			dispatch(setActiveTab(batchType.scheduleCourse));
		} else if (activeTab === batchType.scheduleCourse) {
			// dispatch(setEmptyFormFilled());
			// dispatch(setTopicSessions([]));
			navigate("/batches");
		}
	};

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			case batchType.batchDetails:
				return <BatchDetails />;
			case batchType.verifyStudents:
				return <VerifyStudents status='ADD' batchId=''/>;
			case batchType.teacherDetails:
				return <TeacherDetails />;
			case batchType.scheduleCourse:
				return <ScheduleCourse />;
			case batchType.batchEvents:
				return <BatchEvents />;
			default:
				return <BatchDetails />;
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 1000, margin: "auto", p: 2 }}>
			<Box sx={{}}>
				<Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
					<Typography level='h6'>Create New Batch</Typography>
					<Button
						onClick={handleContinue}
						disabled={!isFormFilled[activeTab]}
						sx={{ alignSelf: "center", backgroundColor: "#000" }}
					>
						{activeTab === batchType.scheduleCourse ? "Create new batch" : "Continue"}
					</Button>
				</Box>

				<Tabs
					value={activeTab}
					onChange={handleTabChange}
				>
					<TabList sx={{ backgroundColor: "#f4f4f4" }}>
						<Tab value={batchType.batchDetails}> 01. Batch Details</Tab>
						<Tab value={batchType.verifyStudents}>02. Student Detials</Tab>
						<Tab value={batchType.teacherDetails}>03. Teacher Details</Tab>
						<Tab value={batchType.scheduleCourse}>04. Schedule</Tab>
					</TabList>
				</Tabs>

				<Box
					sx={{
						p: 2,
						border: "1px solid",
						borderColor: "divider",
						borderRadius: "sm",
						background: "white",
						// maxHeight: "35rem",
					}}
				>
					{renderTabsInfo(activeTab)}
				</Box>
			</Box>
		</Box>
	);
};

export default WithAuth(AddNewBatch);
