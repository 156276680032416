import { Box, Textarea, Typography } from "@mui/joy";
import React from "react";
import DocTask from "./task-view/doc-task";
import CodeTask from "./task-view/code-task";
import McqTask from "./task-view/mcq-task";

const StudentTaskDetails = ({ task, topic }) => {
	const renderTaskType = () => {
		switch (task?.actual_task?.task_type) {
			case "MCQ":
				return <McqTask task={task} />;
			case "CODE":
				return <CodeTask task={task} />;
			case "DOC":
				return <DocTask task={task} />;
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				maxHeight: "70vh",
				overflowY: "auto",
				px: 2,
			}}
		>
			<Box
				sx={{
					display: "flex",
					gap: 2,
					alignItems: "center",
				}}
			>
				<Typography sx={{ color: "#6e6e6f", fontSize: "14px" }}>{topic.topic_name}</Typography>
				<Typography sx={{ color: "#6e6e6f", fontSize: "14px" }}>{task?.actual_task?.task_type}</Typography>
				<Typography sx={{ color: "#6e6e6f", fontSize: "14px" }}>{task?.actual_task?.points} pts</Typography>
			</Box>
			<Box>
				<Box
					sx={{
						mb: 2,
					}}
				>
					<Typography
						level='h6'
						sx={{ color: "#000", fontSize: "14px", fontWeight: "700" }}
					>
						Objective
					</Typography>
					<Textarea
						value={task?.actual_task?.metadata?.question}
						minRows={1}
						maxRows={10}
						readOnly={true}
						sx={{
							width: "100%",
							resize: "none",
							border: "none",
							outline: "none",
							boxShadow: "none",
							padding: "0",
							color: "#050519",
							fontSize: "1rem",
						}}
					/>
				</Box>
				<Box>{renderTaskType()}</Box>
			</Box>
		</Box>
	);
};

export default StudentTaskDetails;
